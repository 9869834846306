@import "BusinessStyleVariable";

.MuiContainer-root {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.MainContainerBusinessVideoDetails {
  background: $bgcolor;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    color: white;
  }

  .DrawerBusinessVideoDetails {
    background: red;
  }

  .ContentBusinessVideoDetails {
    background: $bgcolor;

    .VideoDetailsHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: $secondarybgcolor;
      margin: 8px 8px 0 8px;
      //   padding: 30px 50px 0 50px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .VideoDetailsHeader1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 30px 50px 0 50px;
        width: 100%;
      }

      .VideoDetailsHeader2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: flex-start;
        padding: 0 50px 0 50px;
        width: 100%;
      }
    }
    .VideoDetailsTabContent {
      margin: 0 8px 0 8px;
    }
  }
}

.BasicTabs {
  color: #fff;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  background-color: $bgcolor;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.VideoDetailsTabPanel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  background: $secondarybgcolor;
  padding: 20px 45px 0px 20px;
  font-size: 12px;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: rgb(177, 176, 176) !important;
}

.videoContentContainer {
  display: flex;
  flex-direction: row;
}

.MuiAccordionSummary-contentGutters {
  margin: 0px !important;
  padding: 0px !important;
}
.MuiAccordionSummary-root {
  padding: 0px !important;
  background-color: #2a3135 !important;
  color: #f29600 !important;
}

.videoDetailsTitleContainer {
  color: #fff;
  margin-top: 30px !important;
}

.videoDetailsTitle {
  margin-bottom: 20px !important;
}

.addPlaylistVideo {
  color: #f29600 !important;
  margin-bottom: 20px !important;
  cursor: "pointer";
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  p {
    cursor: pointer;
    margin: 0 10px;
  }
}

.savePlaylist {
  cursor: pointer;
}

.videoSource {
  margin-bottom: 13px !important;
}
.videoDescription {
  margin-bottom: 20px !important;
  max-width: 100%;
}
.insertDescriptionModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: #2a3135;
  border-radius: 7px;
  z-index: 1;
}

.insertDescriptionTextarea {
  background: transparent;
  outline: none;
  border: "1px solid black";
  resize: none;
  font-size: 15px;
  color: white;
}

.insertDescriptionTextarea::placeholder {
  font-size: 15px;
  color: white;
}

.css-e53awj-MuiStack-root {
  display: flex !important;
  justify-content: flex-end !important;
}

.modalCloseDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: #141d22;
}

.modalCloseButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #141d22;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modalCloseButtonIcon {
  font-size: 20px;
  color: white;
}

.modalButtonSave {
  background: #7fc94f;
  outline: none;
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  border-radius: 7px;
  cursor: pointer;
}

.modalButtonCancel {
  background: transparent;
  outline: none;
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  color: #e4e7e9;
  border-radius: 7px;
  border: 1px solid #6a6e72;
  cursor: pointer;
}
