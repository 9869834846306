//**@@ StudentSign In Code

.OTPFormContainer {
  background: #141d22;
  height: 100vh;
}

.SendOTPButton {
  margin-top: 30px !important;
  min-height: 50px !important;
  color: #fff;
  width: 25%;
  background-color: #f29600 !important;
}

.siginTitle {
  display: flex;
  color: #fff;
  justify-content: center;
  font-size: 30px !important;
  font-weight: bold !important;
  margin-top: "10px";
}

.signinSubTitle {
  margin-top: 10px;

  display: flex;
  justify-content: center;
  color: #999b9d;
}

.HashCodeFormContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.resendTokenMessage {
  color: #fff;
  font-weight: 200 !important;
}

.resendTokenLink {
  color: #f29600 !important;
  cursor: pointer;
  font-weight: 500 !important;
}

.resendTokenContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.OTPCodeInputContainer {
  display: flex;
  justify-content: center;
}
.TextFieldContainer {
  display: flex;
  justify-content: center;
}
