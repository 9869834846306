.dzu-dropzone {
  min-height: 0px !important;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dzu-dropzone::-webkit-scrollbar {
  display: none;
}

.dzu-previewContainer {
  display: flex;
  flex-direction: column !important;
  padding: 40px 15px 40px 40px;
}

.dzu-previewStatusContainer {
  margin-top: 10px;
  width: 100%;

  progress {
    width: 100%;
    height: 20px;
  }
}

.dzu-inputLabelWithFiles {
  display: none !important;
}
