@import "CreatorStyleVariable";

.MuiContainer-root {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.MainContainerCreatorVideoDetails {
  background: $bgcolor;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    color: white;
  }

  .DrawerCreatorVideoDetails {
    background: red;
  }

  .ContentCreatorVideoDetails {
    background: $bgcolor;

    .VideoDetailsHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: $secondarybgcolor;
      margin: 8px 8px 0 8px;
      //   padding: 30px 50px 0 50px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .VideoDetailsHeader1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 30px 50px 0 50px;
        width: 100%;
      }

      .VideoDetailsHeader2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: flex-start;
        padding: 0 50px 0 50px;
        width: 100%;
      }
    }
    .VideoDetailsTabContent {
      margin: 0 8px 0 8px;
    }
  }
}

.BasicTabs {
  color: #fff;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  background-color: $bgcolor;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.VideoDetailsTabPanel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  background: $secondarybgcolor;
  padding: 20px 45px 0px 20px;
  font-size: 12px;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: rgb(177, 176, 176) !important;
}

.videoContentContainer {
  display: flex;
  flex-direction: row;
}

.MuiAccordionSummary-contentGutters {
  margin: 0px !important;
  padding: 0px !important;
}
.MuiAccordionSummary-root {
  padding: 0px !important;
  background-color: #2a3135 !important;
  color: #f29600 !important;
}
