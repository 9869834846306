.videoLogo {
    display: block;

    .videoLogoIcon {
        margin: 10px;
        width: 25px;
        height: 21px;
    }

    .footerVideoLogoIcon {
        margin: 0px 10px 0px 10px;
        width: 25px;
        height: 21px;
    }
}

.videoLogoActive {
    display: none;  
    
    .videoLogoActiveIcon {
        margin: 10px;
        width: 25px;
        height: 21px;
    }

    .footerVideoLogoActiveIcon {
        margin: 0px 10px 0px 10px;
        width: 25px;
        height: 21px;
    }
}

.navMenuActive {
    background: #0a0e11;
    color: #F29600;
    border-left: 6px solid #F29600;

    .videoLogo {
        display: none;
    }

    .videoLogoActive {
        display: block;
    }
}

.footerMenuActive {
    color: #F29600;
    border-top: 3px solid #F29600;

    .videoLogo {
        display: none;
    }

    .videoLogoActive {
        display: block;
    }
    
}