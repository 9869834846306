.videoLogo {
    display: block;

    .videoLogoIcon {
        margin: 10px;
        width: 25px;
        height: 21px;
    }

    .footerVideoLogoIcon {
        margin: 0px 10px 0px 10px;
        width: 25px;
        height: 21px;
    }
}

.videoLogoActive {
    display: none;  
    
    .videoLogoActiveIcon {
        margin: 10px;
        width: 25px;
        height: 21px;
    }

    .footerVideoLogoActiveIcon {
        margin: 0px 10px 0px 10px;
        width: 25px;
        height: 21px;
    }
}

.navMenuActiveCreator {
    background: #0a0e11;
    border-left: 6px solid #0098D9;

    .videoLogo {
        display: none;
    }

    .videoLogoActive {
        display: block;
    }
}

.footerMenuActiveCreator {
    color: #0098D9;
    border-top: 3px solid #0098D9;

    .videoLogo {
        display: none;
    }

    .videoLogoActive {
        display: block;
    }
    
}